import '../css/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col , Button} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { konekcijaContext } from '../../../globalne/KonekcijaBaza';
import axios from "axios";
import { useState, useContext, useEffect } from "react";


function DodavanjePitanja(props:any) {

    const konekcija = useContext(konekcijaContext);
    const [pitanje, setPitanje] = useState();
    const [tezina, setTezina] = useState();
    const [odg1, setOdg1] = useState();
    const [odg2, setOdg2] = useState();
    const [odg3, setOdg3] = useState();
    const [odg4, setOdg4] = useState();
    const [tacno, setTacno] = useState();
    const [svaPitanja, setSvaPitanja] = useState([]);



     const sva_pitanja = async () => {
       const response = await axios.post(konekcija+"sva_pitanja");
  //     setKlijenti(response.data);
         setSvaPitanja(response.data);
         console.log(response)
     }

  const columns:any = [
      {
          name: 'Broj',
          cell: (row:any, index: any) => index+1+'.',
          width: "10%"
      },
      {
          name: "Pitanje",
          selector: (row: any) => <b id={row.id}>{row.pitanje}</b>,   
          //sortable: true
          width: "80%"
      },
      {
          name: "Težina",
          selector: (row: any) => <b id={row.id}>{row.tezina}</b>,
          //sortable: true
          width: "10%"
      }
  ];

  useEffect(() => { 
    sva_pitanja()  
  },[]); 




  const dodajPitanje = async () => {

    const response = await axios.post(konekcija+"dodaj_pitanje", 
        { 
            pitanje: pitanje,
            tezina: tezina, 
            odgovorJedan: odg1,
            odgovorDva: odg2,
            odgovorTri: odg3,
            odgovorCetiri: odg4,
            tacno: tacno
        }); 

  }


    return(

        <Container fluid> 
                     
              <Row>
                <Col xs={12} sm={12} md={8} lg={8} className="dodPitDiv">

                        <DataTable
                                    columns={columns}
                                    data={svaPitanja}
                                    pagination
                                    fixedHeader
                                    highlightOnHover
                                    //selectableRowsHighlight
                                    //fixedHeaderScrollHeight='450px'
                                    // selectableRows
                                    //  actions={<button className='btn btn-sm btn-info'>Dogadjaj</button>}
                                    subHeader
                                    // subHeaderComponent={
                                    //     <input
                                    //     type="text"
                                    //     placeholder='Pretraži'
                                    //     className='w-25 form-control'
                                    //     />
                                    // }
                                    />


                </Col>

                <Col xs={12} sm={12} md={4} lg={4} className="dodPitDiv">
                    <br/><br/><br/>
                    <b>Pitanje</b>
                    <input type="text"  className="" width="35px" height="35px" value={pitanje}  onChange={(e:any) => { setPitanje(e.target.value);  } }/>
                    <br/><br/>
                    <b>Težina:</b>
                    <br/>
                    <b>Grupa 1 </b>
                    <input type="radio" name="tezina" className="" value="1" onClick={  (e:any) =>  {  setTezina(e.target.value); }}/>&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Grupa 2 </b>
                    <input type="radio" name="tezina"  className="" value="2" onClick={  (e:any) =>  {  setTezina(e.target.value); }}/> &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Grupa 3 </b>
                    <input type="radio" name="tezina"  className="" value="3" onClick={  (e:any) =>  {  setTezina(e.target.value); }}/> 
                    <br/><br/>
                    <hr/>
                    <b>Odgovor 1</b>
                    <input type="text"  className="" width="35px" height="35px" value={odg1}  onChange={(e:any) => { setOdg1(e.target.value);  } }/>
                    <input type="radio" name="tacno"  className="" value="1" onClick={  (e:any) =>  {  setTacno(e.target.value); }}/>                  
                    <br/>
                    <b>Odgovor 2</b>
                    <input type="text"  className="" width="35px" height="35px" value={odg2}  onChange={(e:any) => { setOdg2(e.target.value);  } }/>
                    <input type="radio" name="tacno"  className="" value="2" onClick={  (e:any) =>  {  setTacno(e.target.value); }}/>
                    <br/>
                    <b>Odgovor 3</b>
                    <input type="text"  className="" width="35px" height="35px" value={odg3}  onChange={(e:any) => { setOdg3(e.target.value);  } }/>
                    <input type="radio" name="tacno"  className="" value="3" onClick={  (e:any) =>  {  setTacno(e.target.value); }}/>
                    <br/>
                    <b>Odgovor 4</b>
                    <input type="text"  className="" width="35px" height="35px" value={odg4}  onChange={(e:any) => { setOdg4(e.target.value);  } }/>
                    <input type="radio" name="tacno"  className="" value="4" onClick={  (e:any) =>  {  setTacno(e.target.value); }}/>
                    <br/>
                    <br/>
                    <Button onClick={  (event) => { dodajPitanje(); } }>Dodaj</Button>
                </Col>
              </Row>
          
        </Container>
    )
}

export default DodavanjePitanja;