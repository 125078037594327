import '../../css/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Row, Col} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import ImgPoen from '../../../slike/poen.gif';


function Igraci(props:any) {

    const cookies = new Cookies();
    const cookiesIgrac = cookies.get('memoryGamePlayer');
    /*ako je igrac true onda njega pokazi uvek na levoj strani...a ako je igrac false onda njega uvek pokazi na levoj strani*/

    if(typeof props.igrac2 != 'undefined'){ 

        return(
        
                <Col sm={12} md={12} lg={12}>  
                    {cookiesIgrac == "true"?
                                        <Row className="PrikazIgraca">                                    
                                                <Col xs={6} sm={6} md={6} lg={6}  className={props.aktivni_igrac_style}> 
                                                    <Row>                    
                                                        <Col xs={12} sm={12} md={6} lg={6}>
                                                            <h3 ref={props.prvi_igrac_imeRef}>{props.ime_igraca}</h3>
                                                            <h3>Broj poena: <b ref={props.prvi_igrac_poeniRef}>{props.igrac_br_poena}</b></h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6} lg={6}>
                                                            {props.igracJedanPoen?<img className="" width="70px" height="70px" src={ImgPoen} alt="poen"/>:null }
                                                        </Col>
                                                    </Row> 
                                                </Col>
                                            
                                            {props.ime_igraca2 ?
                                                <Col xs={6} sm={6} md={6} lg={6}  className={props.aktivni_igrac_style2}>
                                                    <Row> 
                                                        <Col xs={12} sm={12} md={6} lg={6}>
                                                            <h3 ref={props.drugi_igrac_imeRef}>{props.ime_igraca2}</h3>
                                                            <h3>Broj poena: <b ref={props.drugi_igrac_poeniRef}>{props.igrac_br_poena2}</b></h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6} lg={6}>
                                                            {props.igracDvaPoen?<img className="" width="70px" height="70px" src={ImgPoen} alt="poen"/>:null }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            :null}   
                                            
                                        </Row> 
                    :
                                        <Row className="PrikazIgraca">                                                                         
                                            
                                            {props.ime_igraca2 ?
                                            <Col xs={6} sm={6} md={6} lg={6}  className={props.aktivni_igrac_style2}>
                                                <Row> 
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <h3 ref={props.drugi_igrac_imeRef}>{props.ime_igraca2}</h3>
                                                        <h3>Broj poena: <b ref={props.drugi_igrac_poeniRef}>{props.igrac_br_poena2}</b></h3>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        {props.igracDvaPoen?<img className="" width="70px" height="70px" src={ImgPoen} alt="poen"/>:null }
                                                    </Col>
                                                </Row>
                                            </Col>
                                            :null}    
                                           
                                            <Col xs={6} sm={6} md={6} lg={6}  className={props.aktivni_igrac_style}>  
                                                <Row>                    
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <h3 ref={props.prvi_igrac_imeRef}>{props.ime_igraca}</h3>
                                                        <h3>Broj poena: <b ref={props.prvi_igrac_poeniRef}>{props.igrac_br_poena}</b></h3>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        {props.igracJedanPoen?<img className="" width="70px" height="70px" src={ImgPoen} alt="poen"/>:null }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        } 
                </Col>
                
        )

    }else{  //ako je obicna partija solo mec
        return(
            <Col sm={12} md={12} lg={12}>
                <Row className="PrikazIgraca">                                    
                    <Col xs={12} sm={12} md={12} lg={12}  className={props.solo_igrac_style}> 
                        <Row>                      
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <h3 ref={props.prvi_igrac_imeRef}>{props.ime_igraca}</h3>
                                <h3>Broj poena: <b ref={props.prvi_igrac_poeniRef}>{props.igrac_br_poena}</b></h3>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                 {props.igracJedanPoen?<img className="" width="70px" height="70px" src={ImgPoen} alt="poen"/>:null }
                            </Col>                           
                        </Row>
                    </Col>
                </Row> 
            </Col>
        )
    }
}

export default Igraci;