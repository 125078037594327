import { useState, createContext, useMemo} from "react";

export const KvizContext = createContext<any>(null);

export const KvizProvider = (props: any) => {

    const [podaciKviz, setPodaciKviz] = useState<any>([
      {
        id_pitanja: null, //podatak koji koriste dva razlicita igraca, browsera
        vreme: null,
        odgovor: null,
        id_pitanja1: null
      }
    ]);

    const value = useMemo(() => ({
        podaciKviz, setPodaciKviz
    }), [podaciKviz, setPodaciKviz]);

    return(
      <KvizContext.Provider value={value}>
          {props.children}
      </KvizContext.Provider>
    );
  };
