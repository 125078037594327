import 'bootstrap/dist/css/bootstrap.min.css'
import { Button} from 'react-bootstrap';
import { PocModalContext } from "../../../globalne/PocModalContext";
import { konekcijaContext } from '../../../globalne/KonekcijaBaza';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { socketContext } from '../../../globalne/Socket';

function Sat(props:any) {
    const konekcija = useContext(konekcijaContext);
    const {podaci, setPodaci} = useContext(PocModalContext);
    const MySwal = withReactContent (Swal); //sweetalert2
    const cookies = new Cookies();
    const cookiesIgrac = cookies.get('memoryGamePlayer');
    const navigate = useNavigate();
    const socket = useContext(socketContext);
    // const socket = io.connect('http://localhost:3001', {
    //     transports: ['websocket'], 
    //     upgrade: false,
    //     //autoConnect: false
    //     });
    let kanal = props.kanal;

        /*dugme za nazad....povratak na pocetnu....3 slucaja....prvi slucaj iz online partije......drugi slucaj iz lokalne pratije ali za dva igraca....treci slucaj iz solo partije*/
        const Obrisi_kartice = async () => {
            if(typeof props.igrac2 != 'undefined'){ //ovim uslovom kazemo da ovo unutar ovog if-a radi samo kad su dva igraca aktivna ... bilo lokalno bilo online
                const response1 = axios.post(konekcija+"obrisi_kartice", { id_meca:props.id_meca, namerno:'namerno' });
    
                if(podaci[0].da_li_je_online === "online je"){ 
                    MySwal.fire({
                        title: 'Jeste li sigurni',
                        icon: 'error',
                        //iconHtml: '؟',
                        confirmButtonText: 'Da',
                        cancelButtonText: 'Ne',
                        showCancelButton: true,
                        showCloseButton: true
                      }).then((result) => {
                        
                        if (result.isConfirmed === true) {
                            //komIgracu setovano samo na nivou ovog bloka
                            let komIgracu:any;
                            if(cookiesIgrac == "true"){
                                komIgracu = "false";
                            }else{
                                komIgracu = "true";
                            }
                            cookies.remove("memoryGamePlayer");
                            socket.emit("izasao_sam", { kanal, komIgracu }); 
                            navigate("/");
                            window.location.reload();                                                 
                        }
                    })    
                }else{
                    MySwal.fire({
                        title: 'Jeste li sigurni',
                        icon: 'error',
                        //iconHtml: '؟',
                        confirmButtonText: 'Da',
                        cancelButtonText: 'Neا',
                        showCancelButton: true,
                        showCloseButton: true
                      }).then((result) => {
                        if (result.isConfirmed === true) {
                            navigate("/");                                          
                        }
                    })             
                }
            }else{
                MySwal.fire({
                    title: 'Jeste li sigurni',
                    icon: 'error',
                    //iconHtml: '؟',
                    confirmButtonText: 'Da',
                    cancelButtonText: 'Neا',
                    showCancelButton: true,
                    showCloseButton: true
                  }).then((result) => {
                    if (result.isConfirmed === true) {
                        navigate("/");                                          
                    }
                })         
            }
        }
         /*dugme za nazad....povratak na pocetnu....3 slucaja....prvi slucaj iz online partije......drugi slucaj iz lokalne pratije ali za dva igraca....treci slucaj iz solo partije*/

    return(

        <span>
            <Button variant="dark" size="lg" onClick={  (event) => { Obrisi_kartice(); } } >Nazad</Button>
        </span>
    )
}

export default Sat;