import '../css/index.scss';
import { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import {  Row, Col} from 'react-bootstrap';
import { KvizContextBrPitanja } from '../../../globalne/KvizContextBrPitanja';

function PitanjeBroj(props:any) {

    const {podaciKvizBrPitanja, setPodaciKvizBrPitanja} = useContext<any>(KvizContextBrPitanja);
    const [br_pitanja, setBr_pitanja] = useState<any>(podaciKvizBrPitanja[0].br_pitanja);
  
     useEffect(() => { 
        setBr_pitanja(podaciKvizBrPitanja[0].br_pitanja)
    },[podaciKvizBrPitanja[0].br_pitanja]); 

    return(
        <Row className="w-100">     
            <Col xs={4} sm={5} md={5} lg={5} className="pitanjeBrOkolo pitanjeBrOkoloLevo">
               
            </Col>
            <Col xs={4} sm={2} md={2} lg={2} className="pitanjeBr">
                <div className="brPitanje"><b>{br_pitanja}</b></div>
            </Col>
            <Col xs={4} sm={5} md={5} lg={5} className="pitanjeBrOkolo pitanjeBrOkoloDesno">
            
            </Col>
    </Row>
    )
}

export default PitanjeBroj;