import '../css/index.scss';
import { useState, useEffect, useContext, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import {  Row, Col} from 'react-bootstrap';
import { konekcijaContext } from '../../../globalne/KonekcijaBaza';
import { KvizContextBrPitanja } from '../../../globalne/KvizContextBrPitanja';
//import { KvizContextStaJeTacno } from '../../../globalne/KvizContextStaJeTacno';
import { KvizContext } from '../../../globalne/KvizContext';
import { socketContext } from '../../../globalne/Socket';
import { useLocation} from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from "axios";


type Tipovi_podataka = {
    igrac: number;
    igrac2: number;
    kanal:any;
    id_meca:number;
}

function Odgovori(props:any) {
    
    const cookies = new Cookies();
    const cookiesIgrac = cookies.get('kvizGamePlayer');
    const konekcija = useContext(konekcijaContext);
    const {podaciKviz, setPodaciKviz} = useContext<any>(KvizContext);
    const [width, setWidth]   = useState(window.innerWidth); //sirina ekrana
    const {podaciKvizBrPitanja, setPodaciKvizBrPitanja} = useContext<any>(KvizContextBrPitanja);
    //const {staJeTacno, setStaJeTacno} = useContext<any>(KvizContextStaJeTacno);

    const odg1Ref = useRef<any>();
    const odg2Ref = useRef<any>(); 
    const odg3Ref = useRef<any>();
    const odg4Ref = useRef<any>();

    const [odgId1, setOdgId1] = useState<any>();
    const [odg1, setOdg1] = useState<any>();
    const [odgId2, setOdgId2] = useState<any>();
    const [odg2, setOdg2] = useState<any>();
    const [odgId3, setOdgId3] = useState<any>();
    const [odg3, setOdg3] = useState<any>();
    const [odgId4, setOdgId4] = useState<any>();
    const [odg4, setOdg4] = useState<any>();
    const [prviOdg, setPrviOdg] = useState<any>(0);
    const socket = useContext(socketContext);
    const dolaz_param = useLocation();
    const podaci_dolazni = dolaz_param.state as Tipovi_podataka;
    if(cookiesIgrac == "true"){
        var igrac = podaci_dolazni.igrac; 
    }else{
        var igrac = podaci_dolazni.igrac2; 
    }
    const [klasa, setKlasa] = useState<any>('');
    
    var kanal = podaci_dolazni.kanal; 
    var id_meca = podaci_dolazni.id_meca; 
   //var id_pitanja = podaciKviz[0].id_pitanja;

    const PonudjeniOdgovori = async () => {
        
            fetch(konekcija+'ponudjeni_odgovori', {
                method: 'POST', 
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id_pitanja:podaciKviz[0].id_pitanja,
                })        
            }).then(res => res.json())
            .then(data => {
                
                if(data !== ""){

                    setOdgId1(data[0].id);
                    setOdg1(data[0].odgovori);
                    setOdgId2(data[1].id);
                    setOdg2(data[1].odgovori);
                    setOdgId3(data[2].id);
                    setOdg3(data[2].odgovori);
                    setOdgId4(data[3].id);
                    setOdg4(data[3].odgovori);
                }              
            })    
    }

    useEffect(() => { 
        PonudjeniOdgovori();   
    },[podaciKviz[0].id_pitanja]); 

    useEffect(() => { 
         
    },[prviOdg]); 



    // useEffect(() => { 
    //     setKlasa('blink_border')
    // },[staJeTacno[0].id_odgovora]); 


    const MojOdgovor = async (e:any, value: any) => {   
        if(prviOdg === 0){//moze da se klikne na samo jedan od ponudjenih odgovora
            if(podaciKviz[0].vreme > 0){//ako je vreme isteklo nema kliktanja na neki od odgovora
                let id_odgovora = value;
                
                const response = await axios.post(konekcija+"pitanje", { id_meca: id_meca });         
                let id_pitanja = response.data[podaciKvizBrPitanja[0].br_pitanja-1].id_pitanja;

                let komIgracu:any;
                if(cookiesIgrac == "true"){
                    komIgracu = "false";
                }else{
                    komIgracu = "true";
                }
                            fetch(konekcija+'igrac_unos_odgovora', {
                                method: 'POST', 
                                headers: {
                                'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    id_pitanja:id_pitanja,
                                    id_igraca:igrac,
                                    id_odgovora:id_odgovora,
                                })        
                            }).then(res => res.json())
                            .then(data => {
                                    let vreme_odgovora = podaciKviz[0].vreme;
                                    socket.emit("moj_odgovor", {kanal, id_odgovora, komIgracu, cookiesIgrac, vreme_odgovora});                                                                   
                            })               
                setPrviOdg(1);  
                setPodaciKviz(() => [ {odgovor: value}]);         
            }
        }  
    }

    useEffect(() => { 
        socket.on("prikazi_protivniku_moj_odgovor", (data:any) => {    
            let odg = document.getElementById( data.id_odgovora) as HTMLElement; 

            if(cookiesIgrac != data.komIgracu){  //odgovor inicijatora              rgba(128, 128, 128, 0.8)  --  obavezno je biti u ovom formatu sa ovim space i 0.8
                if(odg.style.backgroundColor == "" || odg.style.backgroundColor == "rgba(128, 128, 128, 0.8)"){
                    odg.style.backgroundColor  = '#0af5ce';  //PLAVA
                    odg.style.color = "red";
                }else{  
                    if(width < 600){
                        odg.style.background = "linear-gradient(to bottom, #0af5ce 50%, yellow 50%)";
                        odg.style.color = "red";
                    }else{
                        odg.style.background = "linear-gradient(to right, #0af5ce 50%, yellow 50%)";
                        odg.style.color = "red";
                    }                   
                }
            }else if(cookiesIgrac == data.komIgracu){  //prikaz protivniku          rgba(128, 128, 128, 0.8)  --  obavezno je biti u ovom formatu sa ovim space i 0.8
                if(odg.style.backgroundColor == "" || odg.style.backgroundColor == "rgba(128, 128, 128, 0.8)"){ 
                    odg.style.backgroundColor  = 'yellow';
                }else{
                    if(width < 600){
                        odg.style.background = "linear-gradient(to bottom, #0af5ce 50%, yellow 50%)";
                        odg.style.color = "red";
                    }else{
                        odg.style.background = "linear-gradient(to right, #0af5ce 50%, yellow 50%)";
                        odg.style.color = "red";
                    }
                    
                }
            }                  
        });

        socket.on("restartujem_odgovore", (data:any) => {  
            odg1Ref.current.style.background = 'inherit';
            odg1Ref.current.style.backgroundColor = 'rgba(128,128,128, 0.8)';
            odg1Ref.current.style.color = 'black';

            odg2Ref.current.style.background = 'inherit';
            odg2Ref.current.style.backgroundColor = 'rgba(128,128,128, 0.8)';
            odg2Ref.current.style.color = 'black';

            odg3Ref.current.style.background = 'inherit';
            odg3Ref.current.style.backgroundColor = 'rgba(128,128,128, 0.8)';
            odg3Ref.current.style.color = 'black';

            odg4Ref.current.style.background = 'inherit';
            odg4Ref.current.style.backgroundColor = 'rgba(128,128,128, 0.8)';
            odg4Ref.current.style.color = 'black';
            setPrviOdg(0); 
        })
      },[socket]); 

 
    return(
        <Row className="w-100"> 

            <Col xs={12} sm={12} md={5} lg={5} ref={odg1Ref} className="odgovorJedan odgovor" id={odgId1} onClick={(e:any) => {MojOdgovor(e,odgId1);} }><b className="odgovorJedanText">A.&nbsp;  {odg1}</b></Col>
            <Col xs={12} sm={12} md={2} lg={2}></Col>
            <Col xs={12} sm={12} md={5} lg={5} ref={odg2Ref} className="odgovorDva odgovor" id={odgId2} onClick={(e:any) => {MojOdgovor(e,odgId2);} }><b className="odgovorDvaText">B.&nbsp;  {odg2}</b></Col>
    
            <Col xs={12} sm={12} md={5} lg={5} ref={odg3Ref} className="odgovorTri odgovor" id={odgId3} onClick={(e:any) => {MojOdgovor(e,odgId3);} }><b className="odgovorTriText">C.&nbsp;  {odg3}</b></Col>
            <Col xs={12} sm={12} md={2} lg={2}></Col>
            <Col xs={12} sm={12} md={5} lg={5} ref={odg4Ref} className="odgovorCetiri odgovor" id={odgId4} onClick={(e:any) => {MojOdgovor(e,odgId4);} }><b className="odgovorCetirtText">D.&nbsp;  {odg4}</b></Col>
            
        </Row>    
    )
}

export default Odgovori;