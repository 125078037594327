import { useState, createContext, useMemo} from "react";

export const KvizContextSkala = createContext<any>(null);

export const KvizProviderSkala = (props: any) => {

    const [skala, setSkala] = useState<any>([
      {
        igrac: null
      }
    ]);

    const value = useMemo(() => ({
      skala, setSkala
    }), [skala, setSkala]);

    return(
      <KvizContextSkala.Provider value={value}>
          {props.children}
      </KvizContextSkala.Provider>
    );
  };