import 'bootstrap/dist/css/bootstrap.min.css'
import { Container,  Row, Col, Button} from 'react-bootstrap';
import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useNavigate} from 'react-router-dom';
import axios from "axios";
import { konekcijaContext } from '../../../globalne/KonekcijaBaza';
import '../../css/index.scss';
import On from '../../../slike/audio-speaker-on.png';
import Off from '../../../slike/audio-speaker-off.png';
import Konfete from '../../../slike/konfete.gif';

/*slike alert pobedio-izgubio-nereseno*/
import ImgPobedio from '../../../slike/pobeda.gif';
import ImgWinBoy from '../../../slike/boywin.gif';
import ImgWinGirl from '../../../slike/winnergirl.gif';
import ImgIzgubio from '../../../slike/izgubio.gif';
import ImgAngryBoy from '../../../slike/angryboy.gif';
import ImgAngryGirl from '../../../slike/angrygirl.gif';
import ImgNereseno from '../../../slike/nereseno.gif';
import ImgNeresenoBoy from '../../../slike/boynereseno.gif';
import ImgNeresenoGirl from '../../../slike/neresenogirl.gif';
import ImgNapustioMec from '../../../slike/napustio_mec.gif';
/*slike alert pobedio-izgubio-nereseno*/

import { useLocation, Link } from 'react-router-dom';
import { PocModalContext } from "../../../globalne/PocModalContext";
import { socketContext } from '../../../globalne/Socket';
import Cookies from 'universal-cookie';
import Nazad from './Nazad';
import Sat from './Sat';
import Igraci from './Igraci';
import Kartica from './Kartica';
import Poen from '../audio/Poen.mp3';  // da bi mp3 mogli da importujemo....moramo da dodamo ( declare module '*.mp3'; ) u fajl.....react-app-env.d.ts.....nalazi se u src/
import Flip from '../audio/Flip.mp3';
import Promasaj from '../audio/Promasaj.mp3';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

//aktivni_igrac promenljiva:  kada je true aktivan je prvi igrac kada je false aktivan je drugi igrac

import styles from '../../css/moj.module.scss';

type Tipovi_podataka = {
    igrac: number;
    igrac2: number;
    br_slicica: number;
    id_meca: any;
    kanal:any;
    start_timer:any;
    frontImg:any;
}


function Glavna(props:any) {
    const konekcija = useContext(konekcijaContext);
    const socket = useContext(socketContext);

        //    socket.on('connect', () => {
        //     console.log('Connected');
        //     socket.emit('authentication', {username: "John", password: "secret"});
        //     socket.on('authenticated', function() {
        //         // use the socket as usual
        //     });
        //   });
    
    const navigate = useNavigate();
    const MySwal = withReactContent (Swal); //sweetalert2
    const {podaci, setPodaci} = useContext(PocModalContext);
    const cookies = new Cookies();
    const cookiesIgrac = cookies.get('memoryGamePlayer');
    const novi_poen_mp3 = new Audio(Poen);
    const flip_mp3 = new Audio(Flip);
    const promasaj_mp3 = new Audio(Promasaj);
    const dolaz_param = useLocation();
    const podaci_dolazni = dolaz_param.state as Tipovi_podataka;

    var frontImg = podaci_dolazni.frontImg; 
    let prviPotez = 0;
    let isPaused = false;
    let prviKlik:any;
    let prviKlikIdCard:any;
    let matches:any = 0;
    var timer :any;
    let aktivni_igrac = true;
    let winner:any;
    let onlineJe:any = false;
    let provera:any = null;

    const [kanal, setKanal] = useState(podaci_dolazni.kanal);
    const [igrac, setIgrac] = useState<number>(podaci_dolazni.igrac);
    const [ime_igraca, setIme_igraca] = useState<any>();
    const [igrac_br_poena, setIgrac_br_poena] = useState<any>(0);
    const [solo_igrac_style, setSolo_igrac_style] = useState<any>(styles.solo_igrac);
    const [aktivni_igrac_style, setAktivni_igrac_style] = useState<any>(styles.aktivan_igrac);
    const [igrac2, setIgrac2] = useState<number>(podaci_dolazni.igrac2);
    const [ime_igraca2, setIme_igraca2] = useState<any>();
    const [igrac_br_poena2, setIgrac_br_poena2] = useState<any>(0);
    const [aktivni_igrac_style2, setAktivni_igrac_style2] = useState<any>(styles.neaktivan_igrac);
    const [akt_ne_akt_igrac, setAkt_ne_akt_igrac] = useState(styles.aktivna_igra);
    const [ukp_br_poteza, setUkp_br_poteza] = useState<number>(1000);
    const [ukp_br_slicica, setUkp_br_slicica] = useState<number>(podaci_dolazni.br_slicica);
    const [game, setGame] = useState<any>(); 
    const [winer_provera, setWiner_provera] = useState(false);
    const [sve_karte, setSve_karte] = useState<any>();
    const [sve_slidze, setSve_slidze] = useState<any>();
    const [potez_br, setPotez_br] = useState<number>(0);
    const [id_meca, setId_meca] = useState<number>(podaci_dolazni.id_meca);
    const [start_timer, setStart_timer] = useState<any>(false);
    const [igracJedanPoen, setIgracJedanPoen] = useState(false);
    const [igracDvaPoen, setIgracDvaPoen] = useState(false);

    const drugi_igrac_poeniRef = useRef<any>();
    const drugi_igrac_imeRef = useRef<any>(); 
    const prvi_igrac_poeniRef = useRef<any>();
    const prvi_igrac_imeRef = useRef<any>();
    const trenutni_potez = useRef<any>();
    const okvirRef = useRef<any>();
    const resetRef = useRef<any>();
    const minuti = useRef<any>();
    const sekunde = useRef<any>();
    const sound = useRef<any>();

    const sat = useMemo(() => <Sat start_timer={start_timer} minuti={minuti} sekunde={sekunde}/>, [start_timer,minuti,sekunde]);  
    // ovde ucitavamo comp..sat kojoj dostavljamo podatak true/false da li da startuje ili ne.
    //uz pomoc ovog useMemo re-renderujemo childe comp...sva logika se tamo izvrsava
    const igraci = useMemo(() => 
                <Igraci 
                        aktivni_igrac_style={aktivni_igrac_style} 
                        prvi_igrac_imeRef={prvi_igrac_imeRef} 
                        ime_igraca={ime_igraca} 
                        prvi_igrac_poeniRef={prvi_igrac_poeniRef}
                        igrac_br_poena={igrac_br_poena}

                        aktivni_igrac_style2={aktivni_igrac_style2}
                        ime_igraca2={ime_igraca2}
                        drugi_igrac_imeRef={drugi_igrac_imeRef}
                        drugi_igrac_poeniRef={drugi_igrac_poeniRef}
                        igrac_br_poena2={igrac_br_poena2}
                        igrac2={igrac2}
                        solo_igrac_style={solo_igrac_style}
                        igracJedanPoen={igracJedanPoen}
                        igracDvaPoen={igracDvaPoen}
                />, [aktivni_igrac_style,prvi_igrac_imeRef,ime_igraca,prvi_igrac_poeniRef,igrac_br_poena,aktivni_igrac_style2,ime_igraca2,drugi_igrac_imeRef,drugi_igrac_poeniRef,igrac_br_poena2,igrac2,solo_igrac_style,igracJedanPoen,igracDvaPoen])
    
    const [music_poen, setMusic_poen] = useState<any>(On);

    const Ime_igraca = async () => {
        const response = await axios.post(konekcija+"ime_igraca", { igrac: igrac });
        setIme_igraca(response.data[0].username);
    }

    const Ime_igraca1 = async () => {
        if(typeof igrac2 != 'undefined'){ //ovim uslovom kazemo da ovo unutar ovog if-a radi samo kad su dva igraca aktivna ... bilo lokalno bilo online
            const response = await axios.post(konekcija+"ime_igraca", { igrac: igrac2 });
            setIme_igraca2(response.data[0].username);
        }
    }

    const Sve_slike = async () => {
        const response = await axios.post(konekcija+"pregled_slika1", { br_slicica: ukp_br_slicica });
        resetIgrice(response.data);
        setSve_slidze(response.data);
    }
 
    useEffect(() => { 
    Sve_slike();
    Ime_igraca();
    Ime_igraca1();
    MySwal.close(); //ovim odmah po ucitavanju stranice zatvaramo alert "ceka se mec" koji je aktiviran iz modal_online 
    },[]);

    useEffect(() => { 

        },[winer_provera]);

    


    const MusicUkljuciIskljuci = (value: any) => {
        if(value == 1){
            setMusic_poen(false);
        }else{
            setMusic_poen(true);
        }        
    }
    



    

    const prokazSlika = async (slika : any) => { 
     /*AKO JE VARIJANTA ONLINE ONDA SAMO ISCITAVAMO SLIKE IZ BAZE I PRIKAZUJEMO IH NA OBA UREDJAJA A DODAVANJE KARTICA MECA SE RADI IZ MODALA...KORAK RANIJE
     A AKO LOKALNI GAME ONDA OVDE PRVO UBACI SVE SLIKE U BAZU PA IH ODMAH ZATIM IZCITA*/

    if(podaci[0].da_li_je_online == "online je"){ 
        /*ovako iscitava sve slike iz baze kada se igra online*/
         const response = await fetch(konekcija+'sve_kartice', {
            method: 'POST', 
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id_meca:id_meca,
            })
        
        })
        
        const data =  await response.json();
                        const slikeHTML = data.map( (item :any, index: any) => {  
                                return (  
                                    
                                    <Kartica key={index} frontImg={frontImg} index={index} slikaId={item.slk_id} slikaUrl={item.url} slikaNaziv={item.naziv}    onChildClick={  (event:any) => { klikKartica(event); } }/>
                                )                                         
                            });
        setSve_karte(slikeHTML); 


        /*ovako iscitava sve slike iz baze kada se igra online*/
    }else{  
        /*ovakoje opcija za lokalni game na jednom racunaru */
                    slika.sort((_:any) => Math.random() - 0.5);
                        
                    if(provera === null){  //da nam ne bi unosio dva puta u bazu kartice JEDAN BROWSER ovim smo ga pi......
                    const slikeHTML = slika.map( (slika :any, index: any) => {
                                if(typeof igrac2 != 'undefined'){  
                                    axios.post(konekcija+"dodavanje_mec_kartica", { id_meca: id_meca, id_kartice: slika.id  });
                                }
                    
                            return (                                   
                                <Kartica key={index} frontImg={frontImg}  index={index} slikaId={slika.id} slikaUrl={slika.url} slikaNaziv={slika.naziv}    onChildClick={  (event:any) => { klikKartica(event); } }/>
                            )                           
                        });
                        
                    setSve_karte(slikeHTML);   
                    provera = 0;  
                }
        /*ovakoje opcija za lokalni game na jednom racunaru */
    }      
        
        /*po defaultu - prvo ucitavanje*/ 
        if(cookiesIgrac == "true"){  //po ucitavanju stranice onaj koji je KREIRAO mec ostaje mu enable sva polja da moze da igra prvi           
            setAkt_ne_akt_igrac(styles.aktivna_igra);   
            if(ukp_br_slicica == 8){
                setGame(styles.game8green);
            }else if(ukp_br_slicica == 16){
                setGame(styles.game16green);
            }else if(ukp_br_slicica == 20){
                setGame(styles.game20green);
            }            
        }else if(cookiesIgrac == "false"){ //po ucitavanju stranice onaj koji je PRISTUPIO mecu sva polja su mu disejblovana             
                setAkt_ne_akt_igrac(styles.ne_aktivna_igra);
            if(ukp_br_slicica == 8){
                setGame(styles.game8red);
            }else if(ukp_br_slicica == 16){
                setGame(styles.game16red);
            }else if(ukp_br_slicica == 20){
                setGame(styles.game20red);
            }       
        }else if(typeof cookiesIgrac == 'undefined'){ //ako je lokalni duel ili solo partija
            //setAkt_ne_akt_igrac(styles.aktivna_igra);   
            if(ukp_br_slicica == 8){
                setGame(styles.game8green);
            }else if(ukp_br_slicica == 16){
                setGame(styles.game16green);
            }else if(ukp_br_slicica == 20){
                setGame(styles.game20green);
            }     
        }
        /*po defaultu - prvo ucitavanje*/ 
    } 

    
    
/******************************************************************************************************************/
/******************************************************************************************************************/
/******************************************OPCIJE ZA DVA ONLINE IGRACA*********************************************/
/******************************************************************************************************************/
/******************************************************************************************************************/
    useEffect(() => { 
  
        },[aktivni_igrac]);

    useEffect(() => { 
        if(typeof kanal != 'undefined'){  //bukvalno provera da li je online partija ili nije
            if(podaci[0].da_li_je_online == "online je"){ 
            socket.emit("join_room", kanal); //ako jeste udju u sobu - kanal
            }
        }
    });
    /* sa ove stranice tj iz ove komponente moramo ponovo prvo da udjemo u room - kanal to radimo odmah po ucitavanju stranice*/
    
  useEffect(() => {     

            if(podaci[0].da_li_je_online == "online je"){ 
                socket.on("online_pokretanje_sata", (data:any) => {  
                    if(data.zahtev === "pokreni"){
                        setStart_timer(true);   
                    }             
            });

            socket.on("okreni_karticu", (data:any) => {    //okrece prvu ili, i drugu karticu

                if(data.komIgracu == cookiesIgrac){
                    //console.log('JA FLIPUJEM');
                    let kartica1 = document.getElementById(data.id_card);
                    let [front1, back1] = getFrontAndBackFromCard(kartica1);
                    if(front1.classList.contains("rotated") || isPaused) return;
                    rotateElements([front1, back1]);
                }else{
                    //console.log('JA SAM POSLAO');
                }
            });


            socket.on("zatvori_obe_kartice", (data:any) => {   //okrece obe kartice u prvobitan polozaj

                if(data.komIgracu == cookiesIgrac){
                    setAkt_ne_akt_igrac(styles.aktivna_igra);  //ono suprotnom enable kartice
                    //console.log('JA FLIPUJEM');                   
                    let kartica1 = document.getElementById(data.id_card);
                    let [front1, back1] = getFrontAndBackFromCard(kartica1);

                    if(front1.classList.contains("rotated"))  {
                    rotateElements([front1, back1]);
                    }
                    let kartica2= document.getElementById(data.prviKlikIdCard);
                    let [front2, back2] = getFrontAndBackFromCard(kartica2);

                    if(front2.classList.contains("rotated") ) {
                    rotateElements([front2, back2]);
                    }

                    if(ukp_br_slicica == 8){
                        setGame(styles.game8green);
                    }else if(ukp_br_slicica == 16){
                        setGame(styles.game16green);
                    }else if(ukp_br_slicica == 20){
                        setGame(styles.game20green);
                    }

                }//else{
                    //console.log('JA SAM POSLAO');
                //}
            });

            socket.on("igrac_jedan_aktivan_obelezi", (data:any) => {  
                if(data.igrac_jedan_aktivan == "igrac_jedan_aktivan"){
                    aktivni_igrac = true;
                    setAktivni_igrac_style(styles.aktivan_igrac);
                    setAktivni_igrac_style2(styles.neaktivan_igrac);
                }             
            });

            socket.on("igrac_dva_aktivan_obelezi", (data:any) => {  
                if(data.igrac_dva_aktivan == "igrac_dva_aktivan"){
                    aktivni_igrac = false;
                    setAktivni_igrac_style(styles.neaktivan_igrac);
                    setAktivni_igrac_style2(styles.aktivan_igrac);
                }             
            });

            socket.on("igrac_jedan_dodaj_poen_prikazi", (data:any) => {     
                    setIgrac_br_poena( data.prvi_igr_poeni );      
                    setIgracJedanPoen(true);    
                    
                    setTimeout(async() => {
                        setIgracJedanPoen(false); 
                    },2000);

            });

            socket.on("igrac_dva_dodaj_poen_prikazi", (data:any) => { 
                    setIgrac_br_poena2(data.drugi_igr_poeni); 
                    setIgracDvaPoen(true);
                    
                    setTimeout(async() => {
                        setIgracDvaPoen(false); 
                    },2000);
            });

            socket.on("vracanje_trenutni_potez", (data:any) => {   
                setPotez_br(data.trenutni_pot);                                          
            });
            
            socket.on("hvala_sto_si_me_obavestio", (data:any) => {  
               cookies.remove("memoryGamePlayer");
                
                if(data.komIgracu == cookiesIgrac){ 
                    MySwal.fire({
                        title: 'Protivnik je napustio meč',
                        //text: '',
                        //icon: 'info',
                        timer: 5000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                    })  
                    navigate("/"); //da izbacimo i protivnika
                    setTimeout(async() => {
                        window.location.reload();
                    },3000)
                    
                }                           
            });
            

            // socket.on("disconnect", (data) => {   
            //     alert('kraj igre');
            //     resetRef.current.click();                     
            // });

            socket.on("izgubio_si_pobedio_si", (data:any) => {            
                if(data.komIgracu == cookiesIgrac){ 
                    //get random value from array
                    var losersImg = [ImgAngryBoy,ImgAngryGirl,ImgIzgubio];
                    var loser = losersImg[Math.floor(Math.random() * losersImg.length)];
                    MySwal.fire({
                        title: 'Žao mi je '+data.ime_porazenog+' izgubio-la si',
                        //text: '',
                        //icon: 'info',
                        timer: 5000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        html: "<img src='" + loser + "' style='width:150px;'>"
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.timer) {
                            navigate("/");                            
                        }
                    })
                    setStart_timer(false);
                }else{  //pobedio prvi,drugi ili je nereseno

                    setTimeout(() => {  //dajemo vremena da se definisu poeni...da bi mogli kad ih pokupimo refovima da ih poredimo
                        if(parseInt(prvi_igrac_poeniRef.current.innerText) > parseInt(drugi_igrac_poeniRef.current.innerText)){
                            var winnerImg = [ImgWinBoy,ImgWinGirl,ImgPobedio];
                            var win = winnerImg[Math.floor(Math.random() * winnerImg.length)];
                            MySwal.fire({
                                title: 'Bravo '+ prvi_igrac_imeRef.current.innerText + ' pobedaaa',
                                //icon: 'success',
                                timer: 5000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                html: "<img src='" + win + "' style='width:150px;'>"
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    navigate("/");                            
                                }
                            })
                            winner = 1;
                        }else if(parseInt(prvi_igrac_poeniRef.current.innerText) < parseInt(drugi_igrac_poeniRef.current.innerText)){
                            var winnerImg = [ImgWinBoy,ImgWinGirl,ImgPobedio];
                            var win = winnerImg[Math.floor(Math.random() * winnerImg.length)];
                            MySwal.fire({
                                title: 'Bravo '+ drugi_igrac_imeRef.current.innerText + ' pobedaaa2',
                                //icon: 'success',
                                timer: 5000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                html: "<img src='" + win + "' style='width:150px;'>"
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    navigate("/");                            
                                }
                            })
                            winner = 2;
                        }else if(parseInt(prvi_igrac_poeniRef.current.innerText) === parseInt(drugi_igrac_poeniRef.current.innerText)){
                            var neresenoImg = [ImgNeresenoBoy,ImgNeresenoGirl,ImgNereseno];
                            var nerese = neresenoImg[Math.floor(Math.random() * neresenoImg.length)];
                            MySwal.fire({
                                title: 'Nerešeno',
                                //icon: 'info',
                                timer: 5000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                html: "<img src='" + nerese + "' style='width:150px;'>"
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    navigate("/");                            
                                }
                            })
                            winner = 0;
                        }
                    },1000)
                    setStart_timer(false);
                }
            });
        }
       // }
   },[socket]);

/******************************************************************************************************************/
/******************************************************************************************************************/
/******************************************OPCIJE ZA DVA ONLINE IGRACA*********************************************/
/******************************************************************************************************************/
/******************************************************************************************************************/
      


    const klikKartica = (event:any) => {   
         
        let komIgracu:any;
        if(cookiesIgrac == "true"){
             komIgracu = "false";
        }else{
             komIgracu = "true";
        }
        if(podaci[0].da_li_je_online == "online je"){  
            let zahtev = "pokreni";
            let id_card = event.currentTarget.id;
            socket.emit("zahtev_za_porkretanje_sata", { kanal, zahtev, id_card });  //zahtev za pokretanje vremena na oba uredjaja - dvosmerna komunikacija sa serverom
        }else{   //ako je lokalna partija za dva igraca na jednom kompu onda samo pokrecemo vreme
            setStart_timer(true);
        }

        const slikaKartica = event.currentTarget;
        let id_card = event.currentTarget.id;  
        const [front, back] = getFrontAndBackFromCard(slikaKartica);

        if(front.classList.contains("rotated") || isPaused) return;
        isPaused = true;
        rotateElements([front, back]);

        if(!prviKlik){  //******************************************KLIK NA PRVU SLIKU******************************************
            
            if(sound.current.value == 1){
                flip_mp3.play(); //ZVUK FLIP
            } 

            socket.emit("zahtev_za_okretanje_kartice", { kanal, komIgracu, id_card });
            prviKlik = slikaKartica;
            prviKlikIdCard=id_card;
            isPaused = false;
            if(prviPotez == ukp_br_poteza){
                // if(podaci[0].da_li_je_online === "online je"){ 
                //     socket.emit("kraj_igre", { kanal });
                // }else{
                    alert('kraj igre');
                    resetRef.current.click();
                //}                   
            }
        }else{  

            if(podaci[0].da_li_je_online == "online je"){             
                socket.emit("zahtev_za_okretanje_kartice", { kanal, komIgracu, id_card });
            }

            const drugoizabranoime = slikaKartica.dataset.slikanaziv;
            const prvoizabranoime = prviKlik.dataset.slikanaziv;
            

            if(prvoizabranoime !== drugoizabranoime) { //******************************************DRUGI KLIK - PROMSAJ*****************************************************
                if(sound.current.value == 1){
                    promasaj_mp3.play(); //ZVUK FLIP
                } 
                 const [prviFront, prviBack] = getFrontAndBackFromCard(prviKlik);
                 setTimeout(() =>{  //posle dva promasaja  ovo vraca slike u prvobitan polozaj
                    rotateElements([front, back, prviFront, prviBack]);
                    prviKlik = null;
                    
                    isPaused = false;
                        if(podaci[0].da_li_je_online == "online je"){   
                            if(ukp_br_slicica == 8){
                                setGame(styles.game8red);
                            }else if(ukp_br_slicica == 16){
                                setGame(styles.game16red);
                            }else if(ukp_br_slicica == 20){
                                setGame(styles.game20red);
                            }
                            setAkt_ne_akt_igrac(styles.ne_aktivna_igra);                                                       
                            socket.emit("zahtev_za_zatvaranje_kartice", { kanal, komIgracu, id_card , prviKlikIdCard});                    
                            prviKlikIdCard = null;                                  
                        }                 
                 }, 700);

                 if(typeof igrac2 != 'undefined'){ //ovim uslovom kazemo da ovo unutar ovog if-a radi samo kad su dva igraca aktivna
                    if(aktivni_igrac == true){ //ako je prvi aktivan u drugom kliku....prebaci na drugog    
                        if(podaci[0].da_li_je_online == "online je"){
                            let igrac_dva_aktivan = 'igrac_dva_aktivan';
                            socket.emit("igrac_dva_aktivan", { kanal, igrac_dva_aktivan });
                        }else{ //ako je lokalna partija
                                aktivni_igrac = false;
                                setAktivni_igrac_style(styles.neaktivan_igrac);
                                setAktivni_igrac_style2(styles.aktivan_igrac);
                        }                      
                    }else if(aktivni_igrac == false){ //ako je drugi aktivan u drugom kliku...vrati na prvog 
                        if(podaci[0].da_li_je_online == "online je"){
                            let igrac_jedan_aktivan = 'igrac_jedan_aktivan';
                            socket.emit("igrac_jedan_aktivan", { kanal, igrac_jedan_aktivan });
                        }else{ //ako je lokalna partija
                            aktivni_igrac = true;
                            setAktivni_igrac_style(styles.aktivan_igrac);
                            setAktivni_igrac_style2(styles.neaktivan_igrac);
                        }
                    }
                }
                
            }else{  //*************************************************************************DRUGI KLIK - POGODAK*********************************************************

                matches++;
                // setPoen(true);
                // setTimeout(() => {
                //     setPoen(false);
                // },1000)

                if(sound.current.value == 1){
                    novi_poen_mp3.play(); //ZVUK
                }    
                         
                                       
                /*provera kome dodeljujemo poen...kada je ostvaren poen proveravamo true/false da vidimo kome dodeljujemo poen*/
                if(typeof igrac2 != 'undefined'){  //ovim uslovom kazemo da ovo unutar ovog if-a radi samo kad su dva igraca aktivna
                        if(aktivni_igrac == true){  
                                let prvi_igr_poeni = prvi_igrac_poeniRef.current.innerText; 
                                prvi_igr_poeni = parseInt(prvi_igr_poeni) + 1;

                                if(podaci[0].da_li_je_online == "online je"){                                               
                                    socket.emit("igrac_jedan_dodaj_poen", { kanal, prvi_igr_poeni }); 
                                }else{                                  
                                    setIgrac_br_poena(prvi_igr_poeni);
                                    setIgracJedanPoen(true);       //prikazi slicicu               
                                        setTimeout(async() => {
                                            setIgracJedanPoen(false);   //makni slicicu
                                        },2000);
                                }
                        }else if(aktivni_igrac == false){   
                                let drugi_igr_poeni = drugi_igrac_poeniRef.current.innerText;   
                                drugi_igr_poeni =  parseInt(drugi_igr_poeni) + 1;

                                if(podaci[0].da_li_je_online == "online je"){                                               
                                    socket.emit("igrac_dva_dodaj_poen", { kanal, drugi_igr_poeni }); 
                                }else{                    
                                    setIgrac_br_poena2(drugi_igr_poeni);
                                    setIgracDvaPoen(true);        //prikazi slicicu                 
                                        setTimeout(async() => {
                                            setIgracDvaPoen(false);   //makni slicicu 
                                        },2000);
                                }
                        }
                    }else{  //prvom igracu uvek povecavaj poene kada sam igra
                            let prvi_igr_poeni = prvi_igrac_poeniRef.current.innerText; 
                            setIgrac_br_poena( parseInt(prvi_igr_poeni) + 1 );
                            setIgracJedanPoen(true);       //prikazi slicicu               
                                setTimeout(async() => {
                                    setIgracJedanPoen(false);   //makni slicicu
                                },2000);
                    }
                /*provera kome dodeljujemo poen...kada je ostvaren poen proveravamo true/false da vidimo kome dodeljujemo poen*/
                        

                //***********************************************************ONLINE POBEDA***********************************************************
                //***********************************************************ONLINE POBEDA***********************************************************
                //***********************************************************ONLINE POBEDA***********************************************************
                if(podaci[0].da_li_je_online == "online je"){   
                    let ukp_online_poena =  parseInt(prvi_igrac_poeniRef.current.innerText) + parseInt(drugi_igrac_poeniRef.current.innerText) +1;  
                    let vreme = `${minuti.current.innerText}:${sekunde.current.innerText}`;                                         
                    if(ukp_online_poena == ukp_br_slicica){         

                        let ime_porazenog;
                        if(komIgracu){   //ovo komIgracu je uvek onaj suprotni igrac tj onaj koji je izgubio
                            ime_porazenog = prvi_igrac_imeRef.current.innerText;
                        }else{
                            ime_porazenog = drugi_igrac_imeRef.current.innerText;
                        }
                        socket.emit("javi_mu_da_je_izgubio_pobedio", { kanal, komIgracu, ime_porazenog });
                        
                        /*odredjivanje winera*/
                        if(parseInt(prvi_igrac_poeniRef.current.innerText) > parseInt(drugi_igrac_poeniRef.current.innerText)){
                            winner = 1;
                        }else if(parseInt(prvi_igrac_poeniRef.current.innerText) < parseInt(drugi_igrac_poeniRef.current.innerText)){
                            winner = 2;
                        }else if(parseInt(prvi_igrac_poeniRef.current.innerText) === parseInt(drugi_igrac_poeniRef.current.innerText)){
                            winner = 0;
                        }
                        /*odredjivanje winera*/
                        setWiner_provera(true); 
                        setTimeout(() => {       //dajemo vremena refovima da se napune
                            /*Update podataka u bazu kada igra dva igraca*/                                            
                            const response = axios.post(konekcija+"pobeda_novi_rezultat_duel", { 
                            igrac_poeni: prvi_igrac_poeniRef.current.innerText,
                            igrac_dva_poeni: drugi_igrac_poeniRef.current.innerText,
                            ukp_br_poteza: trenutni_potez.current.innerText,
                            vreme:vreme,
                            winner:winner,
                            id_meca:id_meca
                            });
                            /*Update podataka u bazu kada igra dva igraca*/   
                                
                            /*brisanje samo kartica meca, da ne opterecujemo bazu bezpotrebno*/    
                            const response1 = axios.post(konekcija+"obrisi_kartice", { id_meca:id_meca });
                            /*brisanje samo kartica meca, da ne opterecujemo bazu bezpotrebno*/
                         },500)
                    }
                }
                //***********************************************************ONLINE POBEDA***********************************************************
                //***********************************************************ONLINE POBEDA***********************************************************
                //***********************************************************ONLINE POBEDA***********************************************************


                else{
                    
                    //***********************************************************LOKALNA POBEDA***********************************************************
                    //***********************************************************LOKALNA POBEDA***********************************************************
                    //***********************************************************LOKALNA POBEDA***********************************************************
                    if(matches == ukp_br_slicica){ //pobeda ako je broj poena jadnak broju slicica   
                        //console.log("winner");
                        //alert('Bravo pobedio si');
                        let vreme = `${minuti.current.innerText}:${sekunde.current.innerText}`;  
                        

                        setStart_timer(false); //zaustavljamo vreme kad uspesno zavrsi partiju
                      //  setTimeout(() =>{                       
                            //resetRef.current.click(); //ovo se ipak radi iz modala
                        if(typeof igrac2 != 'undefined'){ //ovim uslovom kazemo da ovo unutar ovog if-a radi samo kad su dva igraca aktivna

                            if(parseInt(prvi_igrac_poeniRef.current.innerText) > parseInt(drugi_igrac_poeniRef.current.innerText)){
                                alert('Bravo '+ prvi_igrac_imeRef.current.innerText + ' pobeda');
                                winner = 1;
                            }else if(parseInt(prvi_igrac_poeniRef.current.innerText) < parseInt(drugi_igrac_poeniRef.current.innerText)){
                                alert('Bravo '+ drugi_igrac_imeRef.current.innerText + ' pobeda');
                                winner = 2;
                            }else if(parseInt(prvi_igrac_poeniRef.current.innerText) === parseInt(drugi_igrac_poeniRef.current.innerText)){
                                alert('nereseno');
                                winner = 0;
                            }
                            
                            setTimeout(() => {       //dajemo vremena refovima da se napune               
                                /*Update podataka u bazu kada igra dva igraca*/                                            
                                const response = axios.post(konekcija+"pobeda_novi_rezultat_duel", { 
                                    igrac_poeni: prvi_igrac_poeniRef.current.innerText,
                                    igrac_dva_poeni: drugi_igrac_poeniRef.current.innerText,
                                    ukp_br_poteza: trenutni_potez.current.innerText,
                                    vreme:vreme,
                                    winner:winner,
                                    id_meca:id_meca
                                    });
                                /*Update podataka u bazu kada igra dva igraca*/
                                    
                                /*brisanje samo kartica meca, da ne opterecujemo bazu bezpotrebno*/
                                const response1 = axios.post(konekcija+"obrisi_kartice", { id_meca:id_meca });
                                /*brisanje samo kartica meca, da ne opterecujemo bazu bezpotrebno*/
                            },500)
                                
                        }else{ //ovo radi ako je samo jedan igrac aktivan
                                /*Upisivanje podataka u bazu kada igra jedan igrac solo*/                                               
                                //const response = axios.post(konekcija+"pobeda_novi_rezultat", { igrac:igrac, br_poteza:prviPotez, br_slicica: ukp_br_slicica, vreme: vreme });
                                /*Upisivanje podataka u bazu kada igra jedan igrac solo*/
                        }
                            setWiner_provera(true);  
                                var winnerImg = [ImgWinBoy,ImgWinGirl,ImgPobedio];
                                var win = winnerImg[Math.floor(Math.random() * winnerImg.length)];
                                MySwal.fire({
                                    title: 'Bravo '+ prvi_igrac_imeRef.current.innerText + ' pobeda',
                                    //icon: 'success',
                                    timer: 5000,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    html: "<img src='" + win + "' style='width:150px;'>"
                                }).then((result) => {
                                    if (result.dismiss === Swal.DismissReason.timer) {
                                        navigate("/");                            
                                    }
                        })                                                                             
                       // }, 600);                       
                    }
                    //***********************************************************LOKALNA POBEDA***********************************************************
                    //***********************************************************LOKALNA POBEDA***********************************************************
                    //***********************************************************LOKALNA POBEDA***********************************************************
                    
                }
                prviKlik = null;
                prviKlikIdCard = null;
                isPaused = false;
            }
             
            if(podaci[0].da_li_je_online == "online je"){ 
                prviPotez++;
                
                let trenutni_pot = trenutni_potez.current.innerText;
                    trenutni_pot = parseInt(trenutni_pot) + 1;

                socket.emit("trenutni_potez", { kanal, trenutni_pot });
            }else{
                prviPotez++; 
                setPotez_br(prviPotez);
            }
            
            if(prviPotez == ukp_br_poteza){
                // if(podaci[0].da_li_je_online == "online je"){ 
                //     socket.emit("kraj_igre", { kanal });
                // }else{
                    alert('kraj igre');
                    resetRef.current.click();
                //}
            }
        }     
    }

    const rotateElements = (elements : any) => {
        if(typeof elements !== 'object' || !elements.length) return;

        elements.forEach((element :any) => element.classList.toggle('rotated'));
    }

    const getFrontAndBackFromCard = (card : any) => {
        const front = card.querySelector(`.${frontImg}`);
        const back = card.querySelector(".back");
  
        return [front, back]
    }

    const resetIgrice =  (slika : any) => { 
        //posle reseta ne pokazuje ponovo modal??????????????????????????????????????????????????????????????????????????????????????????????
        setSve_karte('');
        setPotez_br(0);
        isPaused = true;
        prviKlik = null;
        prviKlikIdCard = null;
        matches = 0;
        prviPotez = 0;
        setWiner_provera(false);
        setStart_timer(false); //zaustavljane tajmera
        setAktivni_igrac_style(styles.aktivan_igrac);
        setIgrac_br_poena(0);
        setAktivni_igrac_style2(styles.neaktivan_igrac);
        setIgrac_br_poena2(0);
        aktivni_igrac = true;
        setTimeout(async() => {
            prokazSlika([...slika, ...slika]);
            isPaused = false;
        },400)
        
    }

    return (

    // <Container fluid id="klijenat_izm" style={{  
    //     // backgroundImage: "url(" + "https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350" + ")",
    //     // backgroundPosition: 'center',
    //     // backgroundSize: 'cover',
    //     // backgroundRepeat: 'no-repeat',
    //   }}>
      
        <Container>
             {winer_provera?<img className="konfete d-block w-100" src={Konfete} alt="winer"/> :null}
            <Row className="redGlavni">
                <Col sm={12} md={12} lg={12}  className="nsl">
                    <h1>Mima i Maša memori</h1>
                </Col>
                
                <Col sm={12} md={12} lg={12}  className="">
                    <header>
                        <b>Potez: <br/><span ref={trenutni_potez}>{potez_br}</span> od {ukp_br_poteza}</b>    
                        {music_poen?
                            <input ref={sound} type="image" id="On"  className="" src={On} value="1" alt="On" width="35px" height="35px" onClick={  () =>  {  MusicUkljuciIskljuci(1); }}/>
                        :   <input ref={sound} type="image" id="Off" className="" src={Off} value="0" alt="Off" width="35px" height="35px" onClick={  () =>  {  MusicUkljuciIskljuci(0); }}/>
                        }                   

                        {onlineJe?<Button id="reset1" ref={resetRef} onClick={  (event) => { resetIgrice(sve_slidze); } } variant="danger" size="lg">Reset</Button>:null}
                        <Nazad id_meca={id_meca} kanal={kanal} igrac2={igrac2}/>
                        {sat}                                                
                    </header>
                </Col>
         
                <Col sm={12} md={12} lg={12}  className={akt_ne_akt_igrac}>                        
                    <div  className={game}  ref={okvirRef} >
                        {sve_karte}
                    </div>                             
                </Col>
                {igraci}
            </Row>
        </Container>
    // </Container>
        
    );

}
export default Glavna;