import '../css/index.scss';
import { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import {  Row, Col} from 'react-bootstrap';
import { KvizContextBrPitanja } from '../../../globalne/KvizContextBrPitanja';
import { KvizContext } from '../../../globalne/KvizContext';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { konekcijaContext } from '../../../globalne/KonekcijaBaza';
import { KvizContextSkala } from '../../../globalne/KvizContextSkala';

import styles from '../../css/moj.module.scss';

//import axios from "axios";

 type Tipovi_podataka = {
   igrac: number;
   igrac2: number;
//   kanal:any;
   id_meca: number;
 }

function PitanjaSkala(props:any) {  
    const konekcija = useContext(konekcijaContext);   
    const {skala, setSkala} = useContext<any>(KvizContextSkala);
    const dolaz_param = useLocation();
    const podaci_dolazni = dolaz_param.state as Tipovi_podataka;
    var id_meca = podaci_dolazni.id_meca; 
    var igrac = podaci_dolazni.igrac; 
    var igrac2 = podaci_dolazni.igrac2;
    const {podaciKvizBrPitanja, setPodaciKvizBrPitanja} = useContext<any>(KvizContextBrPitanja);
    const [brPitanja, setBrPitanja] = useState<any>(podaciKvizBrPitanja[0].br_pitanja-1);  //posto je pitanje definisano kao broj jedan i tako ga i prikazujemo igracima ali js kad izvalci podatke uvek krece od nule umesto od jedinice
    const [style1, setStyle1] = useState<any>(styles.nereseno);
    const [style2, setStyle2] = useState<any>(styles.nereseno);
    const [style3, setStyle3] = useState<any>(styles.nereseno);
    const [style4, setStyle4] = useState<any>(styles.nereseno);
    const [style5, setStyle5] = useState<any>(styles.nereseno);
    const [style6, setStyle6] = useState<any>(styles.nereseno);
    const [style7, setStyle7] = useState<any>(styles.nereseno);
    const [style8, setStyle8] = useState<any>(styles.nereseno);
    const [style9, setStyle9] = useState<any>(styles.nereseno);
    const [style10, setStyle10] = useState<any>(styles.nereseno);

    
   const ProveraOdgovora = async () => {
       // console.log(podaciKvizBrPitanja[0].br_pitanja+1)
        if(skala[0].igrac == "crveni"){
            if((podaciKvizBrPitanja[0].br_pitanja-1) == 1){
                setStyle1(styles.crveni)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 2){
                setStyle2(styles.crveni)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 3){
                setStyle3(styles.crveni)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 4){
                setStyle4(styles.crveni)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 5){
                setStyle5(styles.crveni)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 6){
                setStyle6(styles.crveni)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 7){
                setStyle7(styles.crveni)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 8){
                setStyle8(styles.crveni)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 9){
                setStyle9(styles.crveni)
                setSkala(() => [ {igrac: null}]); 
            }
            else if(podaciKvizBrPitanja[0].br_pitanja == 10){ //?????????????????????????????????????????????????????
                setStyle10(styles.crveni)
                setSkala(() => [ {igrac: null}]); 
            }           
        }else if(skala[0].igrac == "plavi"){
            if((podaciKvizBrPitanja[0].br_pitanja-1) == 1){
                setStyle1(styles.plavi)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 2){
                setStyle2(styles.plavi)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 3){
                setStyle3(styles.plavi)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 4){
                setStyle4(styles.plavi)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 5){
                setStyle5(styles.plavi)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1)== 6){
                setStyle6(styles.plavi)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 7){
                setStyle7(styles.plavi)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 8){
                setStyle8(styles.plavi)
                setSkala(() => [ {igrac: null}]); 
            }
            else if((podaciKvizBrPitanja[0].br_pitanja-1) == 9){ 
                setStyle9(styles.plavi)
                setSkala(() => [ {igrac: null}]); 
            }
            else if(podaciKvizBrPitanja[0].br_pitanja == 10){ //?????????????????????????????????????????????????????
                setStyle10(styles.plavi)
                setSkala(() => [ {igrac: null}]); 
            }
        }
    }
    
    useEffect(() => { 
         
    },[style1,style2,style3,style4,style5,style6,style7,style8,style9,style10]); 

    useEffect(() => { 
        ProveraOdgovora();  
    },[podaciKvizBrPitanja[0].br_pitanja]); 

    return(
        <Row className="w-100 skalaBrojeva">   
            <Col xs={1} sm={1} md={1} lg={1} className="">
            
            </Col>  
            <Col xs={1} sm={1} md={1} lg={1}  className={style1}>
            1 
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} className={style2}>
            2
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}  className={style3}>
            3
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} className={style4}>
            4
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}  className={style5}>
            5
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} className={style6}>
            6
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}  className={style7}>
            7 
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} className={style8}>
            8
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}  className={style9}>
            9
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} className={style10}>
            10
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} className="">
            
            </Col>
        </Row>
    )
}
export default PitanjaSkala;