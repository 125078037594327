import '../../css/index.scss';
import { useState, useEffect } from "react";

function Sat(props:any) {

    const [mins, setMins] = useState<any>(0);
    const [secs, setSecs] = useState<any>(0);
    var timer :any;

    useEffect(() => { 
        
        if(props.start_timer === true){  //ovim startujemo sat
            timer = setInterval(() =>{
                setSecs(secs + 1);
            
                if(secs === 59){
                    setMins(mins + 1);
                    setSecs(0);
                }
            },1000)
            return () => clearInterval(timer);
        }else{                       //ovim restartujemo sat
            setMins(0); //restart tajmera - minuti
            setSecs(0); //restart tajmera - sekunde
        } 
    });

    return(
        <div className="watch">
            <div className="time" >
                <b ref={props.minuti}>{mins<10?"0"+mins:mins} </b>:<b ref={props.sekunde}> {secs<10? "0"+secs: secs}</b>   
            </div>
        </div>
    )
}

export default Sat;