import { useState, createContext, useMemo} from "react";

export const KvizContextBrPitanja = createContext<any>(null);

export const KvizProviderBrPitanja = (props: any) => {

    const [podaciKvizBrPitanja, setPodaciKvizBrPitanja] = useState<any>([
      {
        br_pitanja: 1
      }
    ]);

    const value = useMemo(() => ({
      podaciKvizBrPitanja, setPodaciKvizBrPitanja
    }), [podaciKvizBrPitanja, setPodaciKvizBrPitanja]);

    return(
      <KvizContextBrPitanja.Provider value={value}>
          {props.children}
      </KvizContextBrPitanja.Provider>
    );
  };
