import { useState, createContext, useMemo} from "react";

export const PocModalContext = createContext<any>(null);

export const PocModalProvider = (props: any) => {

    const [podaci, setPodaci] = useState<any>([
      {
        id_meca: null, //podatak koji koriste dva razlicita igraca, browsera
        id_igraca: null,
        id_igraca2: null,
        da_li_je_online:null,
        igrac_1_poeni: 0,
        igrac_2_poeni: 0,
        soundOnOff:true,
        moj_id_meca: null
      }
    ]);

    const value = useMemo(() => ({
        podaci, setPodaci
    }), [podaci, setPodaci]);

    return(
      <PocModalContext.Provider value={value}>
          {props.children}
      </PocModalContext.Provider>
    );
  };
