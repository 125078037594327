import Pocetna from './pages/pocetnaStrana/components/Pocetna';
import Glavna from './pages/glavnaStrana/components/Glavna';
import Kviz from './pages/kvizStrana/components/Kviz';
import DodavanjePitanja from './pages/kvizStrana/components/DodavanjePitanja';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { PocModalProvider } from './globalne/PocModalContext';
import { socketContext, socket } from './globalne/Socket';
import { konekcijaContext, konekcija } from './globalne/KonekcijaBaza';
import { KvizProvider } from './globalne/KvizContext';
import { KvizProviderBrPitanja } from './globalne/KvizContextBrPitanja';
import { KvizProviderSkala } from './globalne/KvizContextSkala';

function App() {
  return (
    <Router>
      <konekcijaContext.Provider value={konekcija}>
        <socketContext.Provider value={socket}>
          <PocModalProvider>
            <KvizProvider>
              <KvizProviderBrPitanja>
                <KvizProviderSkala>
                  <Routes>
                      <Route path="/" /*exact*/ element={<Pocetna/>} />
                      <Route path="/glavna" element={<Glavna br_poteza={0} br_slicica={0}/>} />                  
                      <Route path="/kviz" element={<Kviz/>} />   
                      <Route path="/dodavanje_pitanja" element={<DodavanjePitanja/>} />               
                  </Routes>
                </KvizProviderSkala>
              </KvizProviderBrPitanja>
            </KvizProvider>
          </PocModalProvider>
        </socketContext.Provider>
      </konekcijaContext.Provider>
    </Router>
  );
}

export default App;
