import '../../css/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container,  Row, Col, Button} from 'react-bootstrap';
import { useState, useEffect, useContext, useRef} from "react";
import { useNavigate  } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import uvod from '../../../slike/uvod.gif';
import Rezultati from './Rezultati';
import { konekcijaContext } from '../../../globalne/KonekcijaBaza';
import Online from './Online';


function Pocetna(props:any) {  
    
    const konekcija = useContext(konekcijaContext);
    const start = useRef<any>(); 
    const startPod = useRef<any>();
    const [select_igrac, setSelect_igrac] = useState<any>();
    const [select_igrac2, setSelect_igrac2] = useState<any>();
    const [igrac, setIgrac] = useState<any>();
    const [igrac2, setIgrac2] = useState<any>();
    const [br_slicica, setBr_slicica] = useState<any>(16);
    const navigate = useNavigate();
    const [odbIgre, setOdbIgre] = useState<any>(1);
    const [lokalOnline, setLokalOnline] = useState<any>();
    const [onlineDeo, setOnlineDeo] = useState<any>(true);
    const [odabriIgrice, setOdabriIgriceo] = useState<any>(true);
    const [width, setWidth]   = useState(window.innerWidth); //sirina ekrana

    const sacuvajPodesavanja = () => {
        
        var frontImges = ['front','front1','front2','front3','front4','front5','front6','front7'];
        var frontImg = frontImges[Math.floor(Math.random() * frontImges.length)];
        
        if(typeof igrac2 != 'undefined'){
                fetch(konekcija+'dodavanje_meca', {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        igrac:igrac,igrac2:igrac2,br_slicica:br_slicica
                    })               
                }).then(res => res.json()).then(data => {
                    if(data !== ""){                      
                        navigate("/glavna",{state: {frontImg:frontImg,igrac:igrac,igrac2:igrac2,br_slicica:br_slicica,id_meca:data.id} });       
                    }})
        }else{
            navigate("/glavna",{state: {frontImg:frontImg,igrac:igrac,igrac2:igrac2,br_slicica:br_slicica} });
        }        
    }
    


    const OdabirIgrice = () => {  
        if(odbIgre == '1'){  //Igra memorije 
              setOdabriIgriceo(true);
        }else if(odbIgre == '2'){   //Kviz 
          setOdabriIgriceo(false);
        }
     }
     useEffect(() => { 
        OdabirIgrice();   
    },[odbIgre]); 


    const LokalniIliOnline = () => { 
       if(lokalOnline == '1'){  //Online  
            setOnlineDeo(true);
            if(width < 600){  //ako je telefon
                startPod.current.style.width = '100%';
            }else{
                startPod.current.style.width = '66.66666667%';
            }

       }else if(lokalOnline == '2'){   //lokalni
        startPod.current.style.width = '100%'; 
        setOnlineDeo(false);
       }
    }

    useEffect(() => { 
        LokalniIliOnline();   
    },[lokalOnline]); 


    const Igraci = async () => {

        fetch(konekcija+'igraci', {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json'
            }        
          })
            .then(res => res.json())
            .then(data => {
                
              if(data !== ""){
                  setIgrac(data[0].id);           
                  const svi_igraci = <select data-placeholder="Igrači"  className="form-control" value={igrac}  id="igr"  onChange={(e) => {setIgrac(e.target.value);} }>
                                         {data.map((item: any) => {
                                              return (                                           
                                                  <option key={item.id} value={item.id}>{item.username}</option>                                         
                                              )                           
                                          })}
                                      </select>
                                      
                        setSelect_igrac(svi_igraci);               
               }              
            })
    }


    const Igraci2 = async () => {

        fetch(konekcija+'igraci', {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
             
            })         
          })
            .then(res => res.json())
            .then(data => {
                if(data !== ""){          
                    const svi_igraci = <select data-placeholder="Igrači"  className="form-control" value={igrac2}    onChange={(e) => {setIgrac2(e.target.value);} }>
                                            <option value=''>---</option>  
                                            {data.map((item: any) => {
                                                return (                                           
                                                    <option key={item.id} value={item.id}>{item.username}</option>                                         
                                                )                           
                                            })}
                                        </select>                                   
                            setSelect_igrac2(svi_igraci);               
                }             
            }) 
    }
    
    useEffect(() => { 
            Igraci();
            Igraci2(); 
            document.title = "Mima i Maša memori";    
      },[]); 


    return (         
        <Container fluid className='klijenat_izm'>   
        <img className="d-block w-100" id="uvod" src={uvod} alt="uvod"/>
            <Container className='klijenat_izm1'>
                <Row>
                    <Col sm={9} md={8} lg={8}  className="start_podesavanje box" ref={startPod}>
                    
                        <h1>Mima i Maša memori</h1>            
                        <Form>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label><b>Odabir igre</b></Form.Label>
                            <div className="form-group">
                                    <select  data-placeholder="Igrice"  className="form-control" value={odbIgre} onChange={(e) => { setOdbIgre(e.target.value);  } }>
                                            <option className="" value="1">Igra memorije</option>
                                            <option className="" value="2">Kviz znanja</option>
                                    </select>                     
                            </div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label><b>Online / Lokani meč</b></Form.Label>
                            <div className="form-group">
                                    <select  data-placeholder="Online / Lokani meč"  className="form-control" value={lokalOnline} onChange={(e) => { setLokalOnline(e.target.value);  } }>
                                            <option className="" value="1">Online</option>
                                            <option className="" value="2">Lokani</option>
                                    </select>                     
                            </div>
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">                 
                            <Form.Label><b>Izbor igrača 1</b></Form.Label>
                            <div className="form-group">
                                {select_igrac}                     
                            </div>
                            </Form.Group>  
                            {/* {odabriIgrice?  :null} */}
                            {onlineDeo?null:    
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">                          
                            <Form.Label><b>Izbor igrača 2</b></Form.Label>
                            <div className="form-group">
                                {select_igrac2}                     
                            </div>
                            </Form.Group>  
                            }
                            {odabriIgrice? 
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label><b>Broj sličica</b></Form.Label>
                            <div className="form-group">
                                    <select  data-placeholder="Broj sličica" id="br_sl" className="form-control" value={br_slicica} onChange={(e) => { setBr_slicica(e.target.value);  } }>
                                            <option className="br_slicica" value="8">16</option>
                                            <option className="br_slicica" value="16">32</option>
                                            <option className="br_slicica" value="20">40</option>
                                    </select>                     
                            </div>
                            </Form.Group> 
                            :null}              

                            {onlineDeo?null:<Button type="button" id="logovanje" ref={start} className="btn btn-danger form-control" onClick={  (event) => { sacuvajPodesavanja(); } }>Start<i className="bi-arrow-bar-right"></i></Button>}             
                        </Form>
                    </Col>

                    {onlineDeo?<Online igrac={igrac} br_slicica={br_slicica} odbIgre={odbIgre}/>:null}

                    <Col sm={12} md={12} lg={12}  className="rez_tab">
                        <h2>Rezultati</h2>
                    <Rezultati/>
                    </Col>
                </Row>  
            </Container> 
        </Container> 
    )
}


export default Pocetna;