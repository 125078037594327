import '../../css/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css'

function Kartica(props:any) {
    return(    
        <div className="card" id={ props.index }   onClick={ props.onChildClick }  data-slikanaziv={ props.slikaId }>
            <div className={props.frontImg}></div>
            <div className="back rotated">
                <img src={require(`../../../../slike/${props.slikaUrl}`)} alt={props.slikaNaziv} className="slike" width="160px" height="160px"/>
            </div>         
        </div>
    )
}

export default Kartica;