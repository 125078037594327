import '../css/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container} from 'react-bootstrap';
import Igraci from './IgraciVreme';
import PitanjeBroj from './PitanjeBroj';
import PitanjaSkala from './PitanjaSkala';
import Pitanje from './Pitanje';
import Odgovori from './Odgovori';

function Kviz(props:any) {

    return(

        <Container fluid className='pozadina'>  
            <Container className='pozadina1'>           
                <Igraci/>

                <PitanjeBroj/>

                <PitanjaSkala/>

                <Pitanje/>

                <Odgovori/>
            </Container>
        </Container>
    )
}

export default Kviz;